import * as React from "react";

import {
  Badge,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue as mode,
  Link,
  Icon,
  useBreakpoint,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import BtnRadioGroup from "../components/btn-select/ButtonRadioGroup";
import { Link as RouterLink, NavLink } from "react-router-dom";
import SigninRoot from "../components/login/RootSigninForm";
import SigninIAM from "../components/login/IAMSigninForm";
import UnderlineLink from "../components/login/UnderlineLink";
import { LogoIcon } from "../components/icons/icons";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import Footer from "../components/Footer";
import { Player } from "@lottiefiles/react-lottie-player";
import * as animationData from "../assets/robot.json";
import { MdEdit } from "react-icons/md";
import { HSeparator } from "../components/separator/Separator";
import { BsPersonWorkspace } from "react-icons/bs";
import CompanyName from "../components/decide/CompanyName";

export const DecideStation = () => {
  const [step, setStep] = useState(1);
  const [loginValue, setLoginValue] = React.useState("root");
  const onStepChange = (step) => {
    setStep(step);
  };
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const textColorSecondary = "gray.400";
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Flex position="relative" h="max-content">
      <Flex
        w="100%"
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        maxW={{ md: "66%", lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        <Flex
          maxW={{ base: "100%", md: "380px" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          flexDirection="column"
        >
          <Box maxW="sm" p={{ base: "2", md: "0" }} w="full">
            <Flex
              w="full"
              alignItems="center"
              flexDirection="column"
              mb={{
                base: "14",
                // md: '32',
              }}
            >
              <LogoIcon w="auto" h="74" mx="auto" />
              <Heading size="md">Defense Station</Heading>
            </Flex>
          </Box>
          <Box me="auto" w="full">
            {/* <Button
              w="full"
              fontSize="sm"
              me="0px"
              mb="26px"
              py="15px"
              h="50px"
              borderWidth="1px"
              bg={googleBg}
              color={"brand.700"}
              fontWeight="500"
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
            >
              <Icon as={BsPersonWorkspace} w="20px" h="20px" me="10px" />
              Find your Company Station
            </Button>
            <Flex align="center" mb="25px" w="full">
              <HSeparator />
              <Text color="gray.400" mx="14px">
                or
              </Text>
              <HSeparator />
            </Flex> */}
            <CompanyName />
            {/* {loginValue === "root" ? (
              <SigninRoot onStepChange={onStepChange} />
            ) : (
              <SigninIAM onStepChange={onStepChange} />
            )} */}
            {step == 1 && (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="6"
              >
                <NavLink
                  to="#"
                  style={() => ({
                    width: "fit-content",
                    marginTop: "40px",
                  })}
                >
                  <Flex
                    align="center"
                    ps={{ base: "25px", lg: "0px" }}
                    pt={{ lg: "0px", xl: "0px" }}
                    w="fit-content"
                  >
                    <Icon
                      as={FaChevronLeft}
                      me="12px"
                      h="13px"
                      w="8px"
                      color={textColorSecondary}
                    />
                    <Text ms="0px" fontSize="sm" color={textColorSecondary}>
                      Back to Defense Station
                    </Text>
                  </Flex>
                </NavLink>
              </Flex>
            )}
          </Box>
          <Box
            display={{ base: "none", md: "block" }}
            h="100%"
            minH="100vh"
            color="white"
            top="0"
            w={{ md: "30vw", lg: "40vw", "2xl": "44vw" }}
            position="absolute"
            right="0px"
          >
            <Box
              display={{
                base: "none",
                lg: "flex",
              }}
              flexDirection="column"
              maxH="100%"
              height={"100vh"}
              borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
              overflow="hidden"
              flex="1"
              bg="green.600"
              color="white"
              px="20"
              pt={{ base: "32", md: "15", xl: "20", "2xl": "32" }}
            >
              <Flex>
                <Badge
                  bg="green.700"
                  px="4"
                  py="1"
                  rounded="md"
                  letterSpacing="wide"
                  color="whiteAlpha.900"
                >
                  New and Improved
                </Badge>
              </Flex>
              <Text
                mt="6"
                fontWeight="extrabold"
                fontSize={{
                  base: "2xl",
                  lg: "3xl",
                }}
                maxW="sm"
                letterSpacing="tight"
                lineHeight="normal"
              >
                Quantum Level Encryption.
              </Text>
              <Text mt="5" maxW="md" fontSize="lg">
                Now we encrypt all your data with post-quantum cryptography.
              </Text>
              <Flex
                justifyContent={"center"}
                alignItems="center"
                flexGrow={"1"}
                position="relative"
              >
                <Box
                  position="absolute"
                  bottom={"20"}
                  mt="5"
                  h={{
                    base: "100px",
                    sm: "150px",
                    md: "200px",
                    lg: "300px",
                    xl: "400px",
                    "2xl": "500px",
                    "3xl": "600px",
                    "4xl": "800px",
                  }}
                  w={{
                    base: "100px",
                    sm: "150px",
                    md: "200px",
                    lg: "300px",
                    xl: "400px",
                    "2xl": "500px",
                    "3xl": "600px",
                    "4xl": "800px",
                  }}
                >
                  <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    keepLastFrame={true}
                    src={animationData}
                    height={"100%"}
                    width={"100%"}
                  ></Player>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default DecideStation;
