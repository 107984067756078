import {
  Box,
  chakra,
  Text,
  useColorModeValue as mode,
  useId,
  useRadio,
  VStack,
  HStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
const RadioBox = chakra('div', {
  baseStyle: {
    borderWidth: '1px',
    px: '6',
    pt: '2',
    pb: '2',
    borderRadius: 'sm',
    cursor: 'pointer',
    transition: 'all 0.2s',
    _focus: {
      shadow: 'outline',
    },
  },
});

const CheckboxIcon = (props) => (
  <Box
    fontSize="2xl"
    color={props.checked ? 'blue.600' : mode('gray.300', 'whiteAlpha.400')}
  >
    {props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
);

export const ButtonRadio = (props) => {
  const { label, icon, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(props);
  const id = useId();
  const checkedStyles = {
    bg: mode('blue.50', 'rgb(0 31 71)'),
    borderColor: 'blue.600',
  };
  return (
    <label
      style={{
        width: '100%',
      }}
      {...getLabelProps()}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <HStack spacing="4">
          <CheckboxIcon checked={state.isChecked} />
          <Box>
            <Text fontWeight="extrabold" fontSize="lg">
              {label}
            </Text>
            <Text color={"gray.400"} fontSize="sm">{description}</Text>
          </Box>
        </HStack>
      </RadioBox>
    </label>
  );
};
