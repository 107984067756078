export const SIGNUP_CAPTCHA_TYPE = "send_signup_code";
export const LOGIN_CAPTCHA_TYPE = "send_login_code";
export const VERIFY_SIGNUP_CAPTCHA_TYPE = "verify_signup_code";
export const CREATE_ROOT_USER_CAPTCHA_TYPE = "create_root_user";
export const GET_AUTH_TOKEN_CAPTCHA_TYPE = "get_auth_token";
export const FREE_TIER_STATUS = "billing_free_tier_active";
export const PRO_ACCOUNT_STATUS = "billing_active";
export const TRANSFER_AUTH_CAPTCHA_TYPE = "get_transfer_auth";

export const PERSONAL_USER_TYPE = "root:personal";
export const BUSINESS_USER_TYPE = "root:business";
export const IAM_USER_TYPE = "iam:user";
export const SSO_USER_TYPE = "sso:user";

export const ACCOUNT_TYPE_USER = "personal";
export const ACCOUNT_TYPE_BUSINESS = "business";
