import { HStack, Spinner, Text, Center } from "@chakra-ui/react";
import { Turnstile } from "@marsidev/react-turnstile";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { navigateToUrl } from "single-spa";
import { TRANSFER_AUTH_CAPTCHA_TYPE } from "../constants/constants";
import { captchaKey } from "../constants/api-keys";
import { delay } from "../utils/custom-functions";
import { useSearchParams } from "react-router-dom";

export default function TokenTransfer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFetchingToken, setFetchingToken] = useState(true);
  const [isBrokenLink, setBrokenLink] = useState(false);
  const turnstile = useRef();
  const fetchAuthToken = useCallback(async () => {
    try {
      const { signInWithToken } = await import("@defense-station/auth");
      const { getTranserAuth } = await import("@defense-station/api-service");
      let token = await turnstile.current?.getResponse();
      while (!token) {
        token = await turnstile.current?.getResponse();
        await delay(1000);
      }
      const data = await getTranserAuth({
        token: searchParams.get("token"),
        recaptcha_code: token,
      });
      signInWithToken(data?.data?.iam_v1_GetTransferAuth?.auth_token);
      navigateToUrl("/policy");
    } catch (e) {
      console.log(e);
      setBrokenLink(true);
    }
    // signInWithToken("");
    // navigateToUrl("/policy");
  }, []);

  const init = useCallback(async () => {
    if (searchParams.get("token")) {
      fetchAuthToken();
    } else {
      setBrokenLink(true);
    }
  }, [fetchAuthToken]);

  useEffect(() => {
    init();
  }, [init]);
  return (
    <Center w="full" h="100vh">
      <HStack>
        <Text fontSize={"3xl"} fontWeight={"500"}>
          {!isBrokenLink ? `Logging you in...` : "Link is broken."}
        </Text>
        {!isBrokenLink && <Spinner />}
      </HStack>
      {!isBrokenLink && (
        <Turnstile
          style={{ height: "0px", overflow: "hidden" }}
          ref={turnstile}
          id="codeCaptcha"
          onSuccess={(token) => setFetchingToken(false)}
          options={{
            action: TRANSFER_AUTH_CAPTCHA_TYPE,
          }}
          siteKey={captchaKey}
        />
      )}
    </Center>
  );
}
