import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  Stack,
  useColorModeValue as mode,
  StackItem,
  Text,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { captchaKey } from "../../constants/api-keys";
import { navigateToUrl } from "single-spa";
import { FiArrowLeft } from "react-icons/fi";
import toast from "../toast";
import {
  LOGIN_CAPTCHA_TYPE,
  GET_AUTH_TOKEN_CAPTCHA_TYPE,
} from "../../constants/constants";
import { Turnstile } from "@marsidev/react-turnstile";
import { delay } from "../../utils/custom-functions";
var counter = null;
let counterCount = 10;

const schema = [
  yup.object().shape({
    account_id: yup.string().required(),
    username: yup.string().required(),
  }),
  yup.object().shape({
    login_code: yup.string().required("Verification code is required."),
  }),
];

const IAMSigninForm = (props) => {
  const navigate = useNavigate();
  const textColorBrand = mode("green.600", "white");
  const textColorDetails = mode("navy.700", "gray.600");
  const textColor = mode("navy.700", "white");
  const borderColor = mode("gray.400", "whiteAlpha.100");
  const [stepCount, setStepCount] = useState(1);
  const [sendingLink, setSendingLink] = useState(false);
  const [sentCounter, setSentCounter] = useState(0);
  const [isFetchingToken, setFetchingToken] = useState(true);
  const [isFetchingTokenForAuth, setFetchingTokenAuth] = useState(true);
  const turnstile = useRef();
  const turnstileAuth = useRef();
  const [errorfield, setErrorfield] = useState({
    field: "",
    message: "",
    code: "",
  });
  const changeStepCount = (step) => {
    if (props?.onStepChange) props.onStepChange(step);
    setStepCount(step);
  };

  const resetCodeCaptcha = () => {
    // setFetchingToken(true);
    turnstile.current?.reset();
  };

  const resetAuthCaptcha = () => {
    // setFetchingTokenAuth(true);
    turnstileAuth.current?.reset();
  };

  const sendLoginCode = async (account_id, username) => {
    const { sendLoginCodeIAMRequest } = await import(
      "@defense-station/api-service"
    );
    let token = await turnstile.current?.getResponse();
    while (!token) {
      token = await turnstile.current?.getResponse();
      await delay(1000);
    }
    const rest = await sendLoginCodeIAMRequest(account_id, username, token);
    return rest;
  };
  const onSubmit = async (values, actions) => {
    try {
      if (stepCount == 1) {
        await sendLoginCode(values.account_id, values.username);
        changeStepCount(2);
        resetCodeCaptcha();
        actions.setSubmitting(false);
        actions.setTouched({});
      } else if (stepCount == 2) {
        const {
          iamSignIn,
          isRedirectBackActionSet,
          removeRedirectionBackActionForLogin,
        } = await import("@defense-station/auth");
        let token = await turnstileAuth.current?.getResponse();
        while (!token) {
          token = await turnstileAuth.current?.getResponse();
          await delay(1000);
        }
        await iamSignIn(
          values.account_id,
          values.username,
          values.login_code,
          token
        );
        if (isRedirectBackActionSet()) {
          removeRedirectionBackActionForLogin();
          navigate(-1);
          return;
        }
        navigateToUrl("/sypher");
        changeStepCount(1);
        resetAuthCaptcha();
      }
    } catch (e) {
      resetCodeCaptcha();
      resetAuthCaptcha();
      if (e.response) {
        toast({
          title: e.response.data.code,
          description: "invalid username or password",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      toast({
        title: "Error!",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const onComplete = (code, submitForm, setFieldValue) => {
    setFieldValue("login_code", code);
    setTimeout(() => submitForm(), 100);
    // submitForm();
  };

  const resendLoginCode = async (account_id, username) => {
    try {
      setSendingLink(true);
      await sendLoginCode(account_id, username);
      setSentCounter(10);
      counterCount = 10;
      // setTimeout(() => setSent(false),10000);
      counter = setInterval(() => {
        if (counterCount == 1) {
          clearInterval(counter);
          setSentCounter(0);
        } else {
          counterCount = counterCount - 1;
          setSentCounter(counterCount);
        }
      }, 1000);
      resetCodeCaptcha();
    } catch (e) {
      resetCodeCaptcha();
      if (e.response) {
        toast({
          title: e.response.data.code,
          description: "invalid username or password",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      toast({
        title: "Error!",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
    setSendingLink(false);
  };

  return (
    <Formik
      validationSchema={schema[stepCount - 1]}
      initialValues={{ account_id: "", username: "", login_code: "" }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        submitForm,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Stack spacing="-px">
              {stepCount == 1 ? (
                <>
                  <FormControl id="account-id">
                    <FormLabel srOnly>Account ID</FormLabel>
                    <Input
                      autoFocus
                      isInvalid={
                        errorfield.field === "all" ||
                        (errors.account_id && touched.account_id)
                      }
                      onChange={handleChange}
                      value={values.account_id}
                      size="lg"
                      name="account_id"
                      type="text"
                      required
                      placeholder="Account ID"
                      bg={mode("white", "gray.700")}
                      fontSize="md"
                      roundedBottom="0"
                    />
                  </FormControl>
                  <FormControl id="username">
                    <FormLabel srOnly>Username</FormLabel>
                    <Input
                      name="username"
                      onChange={handleChange}
                      value={values.username}
                      required
                      size="lg"
                      bg={mode("white", "gray.700")}
                      fontSize="md"
                      roundedTop="0"
                      roundedBottom="0"
                      placeholder="Username"
                    />
                  </FormControl>
                  <Turnstile
                    options={{
                      action: LOGIN_CAPTCHA_TYPE,
                    }}
                    sitekey={captchaKey}
                  />
                </>
              ) : (
                <StackItem>
                  <Stack
                    spacing={"2"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <StackItem
                      marginBottom={"3"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text
                        fontSize={"lg"}
                        color={mode("gray.600", "gray.400")}
                        textAlign={"center"}
                      >
                        We've sent a 6-character code to your email.
                      </Text>
                    </StackItem>
                    <StackItem>
                      <Flex>
                        <PinInput
                          autoFocus
                          isInvalid={errors?.login_code}
                          mx="auto"
                          onComplete={(code) =>
                            onComplete(code, submitForm, setFieldValue)
                          }
                          type="alphanumeric"
                        >
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                          />
                        </PinInput>
                      </Flex>
                    </StackItem>
                    <Turnstile
                      id="authCaptcha"
                      style={{ height: "0px", overflow: "hidden" }}
                      ref={turnstileAuth}
                      onSuccess={(token) => setFetchingTokenAuth(false)}
                      options={{
                        action: GET_AUTH_TOKEN_CAPTCHA_TYPE,
                      }}
                      siteKey={captchaKey}
                    />
                  </Stack>
                </StackItem>
              )}
              <Turnstile
                style={{ height: "0px", overflow: "hidden" }}
                ref={turnstile}
                id="codeCaptcha"
                onSuccess={(token) => setFetchingToken(false)}
                options={{
                  action: LOGIN_CAPTCHA_TYPE,
                }}
                siteKey={captchaKey}
              />
            </Stack>
            <LightMode>
              <Button
                isLoading={
                  stepCount == 1
                    ? isFetchingToken || isSubmitting
                    : isFetchingTokenForAuth || isSubmitting
                }
                loadingText={
                  isFetchingToken || isFetchingTokenForAuth
                    ? "Making sure you are a human..."
                    : ""
                }
                size="lg"
                type="submit"
                mt="8"
                w="full"
                backgroundColor={"green.600"}
                colorScheme="green"
                fontSize="md"
                fontWeight="bold"
              >
                {stepCount == 1 ? "Continue" : "Verify"}
              </Button>
            </LightMode>
            {stepCount == 2 && (
              <Stack
                marginTop={"4"}
                spacing={"4"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
              >
                <StackItem>
                  <Text
                    fontWeight="400"
                    fontSize="14px"
                    color={textColorDetails}
                  >
                    Haven't received it?{" "}
                    <Button
                      fontSize="14px"
                      fontWeight="500"
                      _focus={{ boxShadow: "none" }}
                      onClick={() => resendLoginCode(values?.email)}
                      isLoading={sendingLink}
                      disabled={sentCounter}
                      variant={"link"}
                      color={textColorBrand}
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      {sentCounter
                        ? "Resend a new code (" + sentCounter + ")"
                        : "Resend a new code"}
                    </Button>
                  </Text>
                </StackItem>
                <StackItem>
                  <Button
                    onClick={() => changeStepCount(1)}
                    leftIcon={<FiArrowLeft />}
                    variant="link"
                    style={{ textDecoration: "none" }}
                  >
                    Go back
                  </Button>
                </StackItem>
              </Stack>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default IAMSigninForm;
