import { LoggedOutRoute } from "@defense-station/auth";

import { ChakraProvider, Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./css/main.css";
import theme from "./theme/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Suspense from "./pages/Suspense";
import NotFound from "./pages/NotFound";
import DecideStation from "./pages/DecideStation";
import TokenTransfer from "./pages/TokenTransfer";
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const HandleSSO = React.lazy(() => import("./pages/HandleSSO"));
const SSOLogin = React.lazy(() => import("./pages/SSOLogin"));
const PaymentStatus = React.lazy(() => import("./pages/PaymentStatus"));

export default function Root(props) {
  return (
    <Box position={"fixed"} w="100vw" h="100vh" overflow={"auto"}>
      <ChakraProvider theme={theme}>
        <Router basename="/">
          <React.Suspense fallback={<Suspense />}>
            <Routes>
              <Route
                index
                path="/payments/:status"
                element={<PaymentStatus />}
              />
              <Route index path="/auth-transfer" element={<TokenTransfer />} />
              <Route
                path="/"
                element={
                  <LoggedOutRoute>
                    <Outlet />
                  </LoggedOutRoute>
                }
              >
                <Route index path="/login" element={<LoginPage />} />
                <Route index path="/" element={<LoginPage />} />
                <Route index path="/signup" element={<SignUp />} />
                <Route index path="/decide" element={<DecideStation />} />
                <Route
                  index
                  path="/auth/sso/callback"
                  element={<HandleSSO />}
                />
                <Route
                  index
                  path="/sso/login/:account_id"
                  element={<SSOLogin />}
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </React.Suspense>
        </Router>
      </ChakraProvider>
      <ToastContainer />
    </Box>
  );
}
