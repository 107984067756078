import {toast as toastify} from 'react-toastify'


const toast = (data) => {
    switch (data?.status) {
        case "success":
            toastify.success(data?.description, {
                position: toastify.POSITION.TOP_CENTER
            })
            break;
        case "info":
            toastify.info(data?.description, {
                position: toastify.POSITION.TOP_CENTER
            })
            break;
        case "warning":
            toastify.warning(data?.description, {
                position: toastify.POSITION.TOP_CENTER
            })
            break;
        case "error":
            toastify.error(data?.description, {
                position: toastify.POSITION.TOP_CENTER
            })
            break;
        default:
            toastify(data?.description, {
                position: toastify.POSITION.TOP_CENTER
            })
            break;
    }
} 

export default toast