import { Center, HStack, Spinner, StackItem, Text } from '@chakra-ui/react'
import React from 'react'

export default function Suspense() {
  return (
    <Center height={"100vh"} width="100%">
        <HStack alignItems={"center"} justifyContent="center">
                <Text fontSize={"3xl"}>
                    Loading
                </Text>
                <Spinner size={"md"}/>  
        </HStack>
    </Center>
  )
}
