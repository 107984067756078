import { Box } from '@chakra-ui/react';
import * as React from 'react';

const UnderlineLink = (props) => {
  return (
    <Box
      as="a"
      // href="#"
      pos="relative"
      display="inline-block"
      transition="opacity 0.2s"
      _hover={{
        opacity: 0.8,
      }}
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: '2px',
        bottom: 0,
        bg: 'brand.700',
        insetX: 0,
        insetY: 0,
      }}
      {...props}
    />
  );
};

export default UnderlineLink;
